/*.App {*/
/*}*/

/*.Container {*/
/*    background: #ffffff;*/
/*    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;*/
/*    width: 70%;*/
/*    min-height: 50rem;*/
/*    margin: 3rem auto !important;*/
/*    border: 1px solid rgba(0,0,0,0.025);*/
/*    padding: 3rem !important;*/
/*}*/

/*.Left {*/
/*    float: left;*/
/*    width: 70%;*/
/*}*/

/*.Right {*/
/*    float: right;*/
/*    width: 70%;*/
/*}*/

/*.ProfilePhoto {*/
/*    width: 120px;*/
/*    height: 120px;*/
/*    border-radius: 120px;*/
/*}*/

.container {
    width: calc(100% - 4rem);
}

.Name {
    font-size: 2.75rem;
    font-weight: 900;
    letter-spacing: 0.4rem;
    @apply text-primary;
}

.Title {
    font-size: 1.25rem;
    font-weight: 300;
}

hr {
    margin: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.Section .SectionHeading {
    @apply relative;
    @apply pl-4;
    @apply text-primary;
    font-size: 1.125rem;
    letter-spacing: 0.15rem;
    margin-bottom: 1.5rem;
}

.Section .SectionHeading:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 100%;
    @apply bg-primary;
    @apply absolute;
    @apply top-0;
    @apply left-0;
}

p:not(:first-of-type) {
    margin-top: 0.5rem !important;
}

.Section {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Item {
    margin-bottom: 1rem !important;
}

.Item p {
    text-align: justify;
    text-indent: 1rem;
}

.ItemHeading {
    display: flex;
    margin-bottom: 0.5rem !important;
    justify-content: space-between;
}

.ItemTitle {
    @apply leading-6;
}

.ItemTitleTechStack {
    display: flex;
    align-items: center;
    gap: 6px;
}

.ItemTitleTechStack div {}

.ItemTitleInfo {
    font-size: 0.8rem;
    @apply leading-6;
    text-align: right;
    color: #767676;

    display: flex;
    gap: 10px;
    align-items: center;
}

.headerSection {
    gap: 8px;
}

@media (max-width: 768px) {
    .PhotoContainer {
        display: flex;
        align-items: center;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .Name {
        font-size: 2rem;
        font-weight: 600;
    }

    .headerSection {
        flex-direction: column;
        align-items: flex-start;
    }

    .ItemHeading {
        flex-direction: column;
    }

    .ItemTitleInfo {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0px;
    }
}

h4 {
    @apply font-bold;
}

.timeline:not(:first-of-type) {
    padding-top: 1.2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.08)
}

.timeline>ul {
    list-style-type: none;
    margin-left: calc(48px + 1rem / 2)
}

.timeline>ul>li {}

.timeline>ul>li::before {
    content: '';
    position: relative;
    display: block;
    border-radius: 50%;
    height: 0.8rem;
    width: 0.8rem;
    background-color: rgba(0, 0, 0, 0.2);
    top: calc(1.2rem + 0.3rem);
    left: calc(-1.5rem - 0.8rem / 2);
    margin-bottom: calc(0.8rem * -1);
}

.timeline>ul>li>.content {
    position: relative;
    padding: 1.2rem 0;
    display: flex;
}

.timeline>ul>li:not(:last-of-type)>.content::before {
    content: "";
    position: absolute;
    left: calc(-1.5rem - 2px / 2);
    top: calc(calc(1.2rem + 0.3rem) + 0.8rem + 0.8rem);
    height: calc(100% - (2 * 1.2rem));
    width: 2px;
    background-color: rgba(0, 0, 0, 0.08);
}

.timeline>ul>li>.content .items {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tooltip-arrow {
    display: flex;
    justify-content: center;
}