@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-primary;
}


@media print {
    body {
        background-color: white !important;
    }
}